<script>
import { mapActions, mapState } from "vuex";
import TSPItem from "@/components/rating-tsp/TSPItem.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import RatingTspFilters from "@/components/tsp/RatingTspFilters.vue";
import { debounce } from "throttle-debounce";
import { format } from "date-fns";

export default {
  name: "RatingTSP",
  components: {
    RatingTspFilters,
    MainLoader,
    TSPItem,
  },

  data() {
    return {
      isLoading: false,
      ratingList: [],
      periodList: [
        { id: 1, title: "За все время" },
        { id: 1, title: "За текущий месяц" },
        { id: 1, title: "За прошлый месяц" },
        { id: 1, title: "За текущий год" },
      ],
      selectedPeriod: { id: 1, title: "За все время" },
    };
  },

  computed: {
    ...mapState({
      rating: (state) => state.tsp.rating,
    }),

    lastRefreshedAt() {
      if (!this.rating?.last_refreshed_at) {
        return;
      }

      return format(
        new Date(this.rating.last_refreshed_at),
        "dd.MM.yyyy, HH:mm"
      );
    },
  },

  methods: {
    ...mapActions({
      loadTSPRating: "tsp/loadTSPRating",
    }),

    updateRating: debounce(200, function (params) {
      this.isLoading = true;

      this.loadTSPRating(JSON.parse(JSON.stringify(params))).finally(() => {
        this.ratingList = this.rating.rating;
        this.isLoading = false;
      });
    }),
  },
};
</script>

<template>
  <div class="rating-tsp">
    <RatingTspFilters
      class="rating-tsp__filters"
      @updateRating="updateRating"
    />

    <div class="rating-tsp__update-information">
      <p>Последнее обновление: {{ lastRefreshedAt }}</p>
      <sub>Рейтинг обновляется каждые 3 часа</sub>
    </div>

    <MainLoader v-if="isLoading" />

    <ul v-else-if="ratingList.length > 0">
      <TSPItem
        v-for="(tsp, index) in ratingList"
        :key="tsp.id"
        :tsp="tsp"
        :rating-place="index + 1"
      />
    </ul>

    <p v-else>По выбранным фильтрам ТСП не найдены</p>
  </div>
</template>

<style lang="scss" scoped>
.rating-tsp {
  display: flex;
  padding: 0 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  &__filters {
    width: 100%;
  }

  > ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__update-information {
    p {
      @include text-2;
      color: $dark-primary;
    }

    sub {
      @include caption-1;
      color: $dark-fifth;
    }
  }

  p {
    @include body-1;
    color: $dark-primary;
  }
}

@media (min-width: 576px) {
  .rating-tsp {
    padding: 0 48px 48px;
  }
}
</style>
