<script>
import { mapActions, mapState } from "vuex";
import MainSelect from "@/components/helpers/MainSelect.vue";
import MainButton from "@/components/helpers/MainButton.vue";

const DEFAULT_PARAMS = {
  period: {
    id: "all",
    value: "all",
    name: "За все время",
  },
  tariff: {
    id: "all",
    value: "all",
    name: "Все тарифы",
  },
  country: {
    id: "all",
    value: "all",
    name: "Все страны",
  },
  city: {
    id: "all",
    value: "all",
    name: "Все города",
  },
  type: "sum",
};

export default {
  components: { MainButton, MainSelect },

  data() {
    return {
      params: {
        period: {
          id: "all",
          value: "all",
          name: "За все время",
        },
        tariff: {
          id: "all",
          value: "all",
          name: "Все тарифы",
        },
        country: {
          id: "all",
          value: "all",
          name: "Все страны",
        },
        city: {
          id: "all",
          value: "all",
          name: "Все города",
        },
        type: "sum",
      },

      periods: [
        {
          id: "all",
          value: "all",
          name: "За все время",
        },
        {
          id: "month",
          value: "current_month",
          name: "За текущий месяц",
        },
        {
          id: "previous",
          value: "previous_month",
          name: "За прошлый месяц",
        },
        {
          id: "year",
          value: "current_year",
          name: "За текущий год",
        },
      ],
      countries: [
        {
          id: "all",
          value: "all",
          name: "Все страны",
        },
        {
          id: "ru",
          value: "rub",
          name: "Россия",
        },
        {
          id: "kz",
          value: "kzt",
          name: "Казахстан",
        },
      ],
      tariffsList: [
        {
          id: "all",
          value: "all",
          name: "Все тарифы",
        },
        {
          id: "lite_p",
          value: "lite_p",
          name: "Лайт",
        },
        {
          id: "promo_p",
          value: "promo_p",
          name: "Промо",
        },
        {
          id: "pluspro_p",
          value: "pluspro_p",
          name: "Про+",
        },
      ],

      cities: [
        {
          id: "all",
          value: "all",
          name: "Все города",
        },
      ],

      cityCount: 20,
    };
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.$emit("updateRating", this.params);
      },
    },
  },

  computed: {
    ...mapState({
      citiesState: (state) => state.tsp.cities,
    }),

    isShowResetButton() {
      return JSON.stringify(DEFAULT_PARAMS) !== JSON.stringify(this.params);
    },
  },

  methods: {
    ...mapActions({
      loadCities: "tsp/loadCities",
    }),

    setDefaultParams() {
      this.params = JSON.parse(JSON.stringify(DEFAULT_PARAMS));
    },

    lazyLoad() {
      this.cities.push(
        ...this.citiesState.slice(this.cityCount, this.cityCount + 10)
      );
      this.cityCount += 10;
    },

    searchCity(value) {
      if (value === "") {
        this.cityCount = 20;
        this.cities = [
          {
            id: "all",
            value: "all",
            name: "Все города",
          },
          ...this.citiesState.slice(0, this.cityCount),
        ];
        return;
      }

      this.cities = this.citiesState.filter((city) => {
        return city.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
    },
  },

  beforeMount() {
    this.loadCities().then(() => {
      this.cities = [...this.citiesState.slice(0, this.cityCount)];
    });
    this.$emit("updateRating", this.params);
  },
};
</script>

<template>
  <div class="rating-tsp-filters">
    <div class="rating-tsp-filters__switch switch">
      <button
        class="switch__button"
        type="button"
        :class="{ switch__button_active: params.type === 'sum' }"
        @click="params.type = 'sum'"
      >
        Оборот
      </button>

      <button
        class="switch__button"
        type="button"
        :class="{ switch__button_active: params.type === 'count' }"
        @click="params.type = 'count'"
      >
        Транзакции
      </button>
    </div>

    <div class="rating-tsp-filters__wrapper">
      <MainSelect
        :selected-option.sync="params.period"
        :options="periods"
        :style-type="'background-white'"
      />
      <MainSelect
        :selected-option.sync="params.tariff"
        :options="tariffsList"
        :style-type="'background-white'"
      />
      <MainSelect
        :selected-option.sync="params.country"
        :options="countries"
        :style-type="'background-white'"
      />
      <MainSelect
        :selected-option.sync="params.city"
        :options="cities"
        :style-type="'background-white'"
        use-observer
        use-search
        @intersect="lazyLoad"
        @search="searchCity"
      />
    </div>

    <MainButton
      v-if="isShowResetButton"
      class="rating-tsp-filters__reset-button"
      :title="'Сбросить фильтры'"
      :color="'blue'"
      @click="setDefaultParams"
    />
  </div>
</template>

<style lang="scss" scoped>
.rating-tsp-filters {
  .switch {
    width: 100%;
    display: flex;
    gap: 0;
    margin-bottom: 32px;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      @include text-2;
      color: $dark-primary;
      background: $light-primary;
      border: none;
      width: 100%;
      height: 40px;
      transition: all 0.3s ease;

      &_active {
        background: $dark-primary !important;
        color: $light-primary !important;
      }

      &:first-of-type {
        border-radius: 8px 0 0 8px;
      }

      &:last-of-type {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  &__wrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }

  & ::v-deep .main-select {
    max-width: 100%;
  }

  & ::v-deep .main-select_background-white .main-select__items {
    max-width: none;
  }

  &__reset-button {
    margin-top: 16px;
  }
}

@media (max-width: 900px) {
  .rating-tsp-filters {
    &__wrapper {
      flex-direction: column;
      gap: 16px;
    }

    & ::v-deep .main-select {
      max-width: none;
    }
  }
}

@media (min-width: 900px) {
  .rating-tsp-filters {
    &__wrapper {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media (min-width: 1024px) {
  .rating-tsp-filters {
    max-width: 700px;

    &__wrapper {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
}
</style>
