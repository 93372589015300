<script>
import MainButton from "@/components/helpers/MainButton.vue";
import vClickOutside from "v-click-outside";

export default {
  name: "TSPItem",
  components: { MainButton },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    tsp: {
      type: Object,
      required: true,
    },

    ratingPlace: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isShowInfo: false,
    };
  },

  computed: {
    tspImage() {
      if (!this.tsp.logo) {
        return require("../../assets/images/no_tsp_logo.png");
      }

      return this.tsp.logo;
    },

    partnerPhoto() {
      if (!this.tsp.avatar) {
        return require("../../assets/images/no_avatar.png");
      }

      return this.tsp.avatar;
    },

    tariff() {
      switch (this.tsp.trade_point_tariff) {
        case "lite_p":
          return "Лайт";
        case "promo_p":
          return "Промо";
        case "pluspro_p":
          return "Про+";
        default:
          return "Без тарифа";
      }
    },
  },

  methods: {
    openTSPLink() {
      window.open(this.tsp.client_service_url, "_blank");
    },

    hideItem() {
      this.isShowInfo = false;
    },
  },
};
</script>

<template>
  <li v-click-outside="hideItem" class="tsp-item">
    <button
      type="button"
      class="tsp-item__main-info"
      @click="isShowInfo = !isShowInfo"
    >
      <span class="tsp-item__logo">
        <img :src="tspImage" alt="logo" />
        <span>{{ ratingPlace }}</span>
      </span>

      <sub>{{ tsp.trade_point_name }}</sub>

      <span class="icon-arrow" :class="{ 'icon-arrow_open': isShowInfo }" />
    </button>

    <section
      class="tsp-item__content-wrapper content-wrapper"
      :class="{ 'content-wrapper_open': isShowInfo }"
    >
      <div
        class="tsp-item__content"
        :class="{ 'tsp-item__content_open': isShowInfo }"
      >
        <div class="tsp-item__distrib-info">
          <div>
            <span class="icon-rating-item-tsp" />
            <p>Тариф</p>
            <mark :class="tsp.trade_point_tariff">{{ tariff }}</mark>
          </div>

          <div>
            <img :src="partnerPhoto" alt="avatar" />
            <h5>{{ tsp.inviter_first_name }} {{ tsp.inviter_last_name }}</h5>
            <sub>Подключил ТСП</sub>
          </div>
        </div>

        <MainButton
          title="Перейти к ТСП"
          color="outlined-grey"
          @click="openTSPLink"
        />
      </div>
    </section>
  </li>
</template>

<style lang="scss" scoped>
.tsp-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  background: $light-primary;

  &__main-info {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
  }

  &__logo {
    position: relative;

    img {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      overflow: hidden;
      object-fit: cover;
      border-radius: 100%;
    }

    span {
      position: absolute;
      bottom: -3px;
      right: 50%;
      transform: translateX(50%);
      display: flex;
      width: 18px;
      height: 18px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $dark-primary;
      border: 2px solid $light-primary;
      border-radius: 100%;
      @include caption-1-bold;
      background: $light-third;
    }
  }

  sub {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    @include body-1-bold;
    color: $dark-primary;
  }

  .icon-arrow {
    min-width: 32px;
    height: 32px;
    background: $dark-fifth;
    transform: rotate(180deg);
    transition: transform 0.2s ease;

    &_open {
      transform: rotate(0deg);
    }
  }

  .content-wrapper {
    display: grid;
    opacity: 0;
    grid-template-rows: 0fr;
    transition: all 0.3s ease-out;
    padding: 0;
    gap: 16px;

    &_open {
      opacity: 1;
      grid-template-rows: 1fr;
    }
  }

  &__content {
    display: grid;
    grid-template-rows: 0fr;
    gap: 16px;
    overflow: hidden;
    transition: all 0.5s ease-out;

    &_open {
      grid-template-rows: 1fr;
      padding-top: 16px;
    }
  }

  &__distrib-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    div {
      display: flex;
      padding: 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid $light-fourth;
      column-gap: 8px;

      p {
        width: 100%;
        @include body-1;
        color: $dark-primary;
      }

      mark {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        border-radius: 8px;
        @include caption-1;
      }

      &:last-of-type {
        column-gap: 12px;
        display: grid;
        grid-template-columns: 32px 1fr;
        grid-template-rows: auto auto;
      }

      img {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        object-fit: cover;
        grid-row: -1/1;
      }

      h5 {
        @include text-2-bold;
        color: $dark-primary;
      }

      sub {
        @include caption-1;
        color: $dark-fifth;
        grid-column: 2/2;
      }
    }
  }

  .icon-rating-item-tsp {
    min-width: 20px;
    height: 20px;
    background: $dark-primary;
  }

  &:nth-child(1) {
    .tsp-item__logo > span {
      background: linear-gradient(
          180deg,
          rgba(247, 229, 71, 0.2) 8.13%,
          rgba(254, 252, 175, 0.2) 20.67%,
          rgba(248, 220, 32, 0.2) 34.76%,
          rgba(230, 122, 11, 0.2) 52.27%,
          rgba(248, 220, 32, 0.2) 87.97%
        ),
        #ffda7a;
    }
  }

  &:nth-child(2) {
    .tsp-item__logo > span {
      background: linear-gradient(
          180deg,
          rgba(200, 208, 229, 0.2) 7.93%,
          rgba(250, 250, 250, 0.2) 20.99%,
          rgba(200, 209, 226, 0.2) 34.91%,
          rgba(86, 107, 124, 0.2) 52.32%,
          rgba(169, 184, 207, 0.2) 88.01%
        ),
        #969696;
      color: $light-primary;
    }
  }

  &:nth-child(3) {
    .tsp-item__logo > span {
      background: linear-gradient(
          180deg,
          rgba(237, 185, 102, 0.2) 8.12%,
          rgba(221, 220, 215, 0.2) 20.67%,
          rgba(233, 184, 125, 0.2) 34.76%,
          rgba(133, 58, 39, 0.2) 52.27%,
          rgba(211, 149, 76, 0.2) 87.99%
        ),
        #f4943b;
      color: $light-primary;
    }
  }
}

.lite_p {
  color: $dark-primary;
  background: $light-third;
}

.promo_p {
  color: $dark-primary;
  background: $gold-main;
}

.pluspro_p {
  color: $light-primary;
  background: var(
    --other-pro-plus,
    linear-gradient(164deg, #86759c 0%, #766987 54.69%, #282330 100%)
  );
}

@media (min-width: 576px) {
  .tsp-item__distrib-info {
    flex-direction: row;
    width: 100%;

    div {
      width: 100%;
    }
  }
}

@media (min-width: 1024px) {
  .tsp-item {
    max-width: 700px;
  }
}
</style>
